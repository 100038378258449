<template>
  <div class="dialogBox">
    <el-dialog
      title="垃圾投递信息详情"
      :visible.sync="dialogVisible"
      width="45%"
      @close="handleClose"
    >
      <el-row :gutter="20" class="contentBox">
        <el-col :span="10" class="contentBox_left">
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="12">区县</el-col>
            <el-col :span="12">{{ item.areaName?item.areaName:'未知' }}</el-col>
          </el-row>
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="12">投放点</el-col>
            <el-col :span="12">{{ item.communityVillageName?item.communityVillageName:'未知' }}</el-col>
          </el-row>
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="12">用户姓名</el-col>
            <el-col :span="12">{{ item.userName?item.userName:'未知' }}</el-col>
          </el-row>
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="12">收集员姓名</el-col>
            <el-col :span="12">{{ item.collector?item.collector:'未知' }}</el-col>
          </el-row>
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="12">地址</el-col>
            <el-col :span="12">{{ item.detailedAddress?item.detailedAddress:'未知' }}</el-col>
          </el-row>
        </el-col>
        <el-col :span="14" class="contentBox_right">
          <div class="imgBox">
            <img :src="item.trashCanPhotos" alt="" />
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      item:{},
      userList: [
        {
          one: "区县",
          two: "未知",
        },
        {
          one: "投放点",
          two: "未知",
        },
        {
          one: "用户姓名",
          two: "未知",
        },
        {
          one: "收集员姓名",
          two: "未知",
        },
        {
          one: "地址",
          two: "未知",
        },
      ],
    };
  },

  created() {

  },

  methods: {
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    openClick(item) {
      console.log(item,'999');
      this.item = item;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang='scss'>
*{
  box-sizing: border-box;
}
.dialogBox {
  ::v-deep .el-dialog {
    background: rgb(0, 92, 168) !important;
    border-radius: 8px;
    overflow: hidden;
    .el-dialog__header {
		padding: 1.25rem 1.25rem 0.625rem;
      .el-dialog__title {
        color: #fff !important;
        font-weight: 700;
        font-size: 1.4vw;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: rgb(51, 125, 185) !important;
        font-size: 1.5vw;
        font-weight: 700;
      }
    }
    .el-dialog__body {
      background: rgb(25, 56, 114) !important;
      color: #fff;
      padding: 15px !important;
      height: 45vh;
      overflow: hidden;

      .contentBox {
        height: 100%;
        .contentBox_left {
          border: 1px dashed #ccc;
          border-radius: 8px;
          height: 100%;
          padding: 20px 10px;
          .contentBox_left_hang {
            margin-bottom: 0.8vw;
            font-size: 1vw;
            .el-col:nth-child(1) {
              text-align: right;
            }
          }
        }
        .contentBox_right {
          height: 100%;
          .imgBox {
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>

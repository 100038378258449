import request from '@/utils/request'

// 查询投递垃圾信息上传列表
export function listDelivery(query) {
  return request({
    url: '/business/delivery/mainPageList',
    method: 'get',
    params: query
  })
}
//生活垃圾周趋势图
export function leftGarbageList(query) {
  return request({
    url: '/business/delivery/leftGarbageList',
    method: 'get',
    params: query
  })
}
//首页地区垃圾投递情况
export function garbageDeliveryInHomeArea(query) {
  return request({
    url: '/business/delivery/garbageDeliveryInHomeArea',
    method: 'get',
    params: query
  })
}
//垃圾投递点收集情况
export function collectionAtGarbageDeliveryPoint(query) {
  return request({
    url: '/business/delivery/collectionAtGarbageDeliveryPoint',
    method: 'get',
    params: query
  })
}
//获取部门列表
export function regionSelector(query) {
  return request({
    url: '/business/delivery/regionSelector',
    method: 'get',
    params: query
  })
}
// 查询投递垃圾信息上传详细
export function getDelivery(id) {
  return request({
    url: '/business/delivery/' + id,
    method: 'get'
  })
}

// 新增投递垃圾信息上传
export function addDelivery(data) {
  return request({
    url: '/business/delivery',
    method: 'post',
    data: data
  })
}

// 修改投递垃圾信息上传
export function updateDelivery(data) {
  return request({
    url: '/business/delivery',
    method: 'put',
    data: data
  })
}

// 删除投递垃圾信息上传
export function delDelivery(id) {
  return request({
    url: '/business/delivery/' + id,
    method: 'delete'
  })
}

// 查询投递垃圾信息上传详细
export function getInfo() {
  return request({
    url: '/business/delivery/info',
    method: 'get'
  })
}

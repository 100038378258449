<template>
  <div class="dialogBox">
    <el-dialog
      title="预警详情"
      :visible.sync="dialogVisible"
      width="45%"
      @close="handleClose"
    >
      <el-row :gutter="20" class="contentBox">
        <el-col :span="10" class="contentBox_left">
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="3">设备编号</el-col>
            <el-col :span="19">{{ item.realEquipmentNo?item.realEquipmentNo:'未知' }}</el-col>
          </el-row>
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="3">所属地区</el-col>
            <el-col :span="19">{{ item.equipmentAddress?item.equipmentAddress:'未知' }}</el-col>
          </el-row>
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="3">异常类型</el-col>
            <el-col :span="19">{{ item.warningTypeStr?item.warningTypeStr:'未知' }}</el-col>
          </el-row>
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="3">预警时间</el-col>
            <el-col :span="19">{{ item.warningTime?item.warningTime:'未知' }}</el-col>
          </el-row>
          <el-row
            :gutter="10"
            class="contentBox_left_hang"
          >
            <el-col :span="3">处理状态</el-col>
            <el-col :span="19">{{ item.treatmentStateStr?item.treatmentStateStr:'未知' }}</el-col>
          </el-row>
          <el-row
              :gutter="10"
              class="contentBox_left_hang"
          >
            <el-col :span="3">处理人</el-col>
            <el-col :span="19">{{ item.treatmentBy?item.treatmentBy:'未知' }}</el-col>
          </el-row>
          <el-row
              :gutter="10"
              class="contentBox_left_hang"
          >
            <el-col :span="3">处理时间</el-col>
            <el-col :span="19">{{ item.treatmentTime?item.treatmentTime:'未知' }}</el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      item:{},
      userList: [
        {
          one: "区县",
          two: "未知",
        },
        {
          one: "投放点",
          two: "未知",
        },
        {
          one: "用户姓名",
          two: "未知",
        },
        {
          one: "收集员姓名",
          two: "未知",
        },
        {
          one: "地址",
          two: "未知",
        },
      ],
    };
  },

  created() {

  },

  methods: {
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    openClick(item) {
      console.log(item,'999');
      this.item = item;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang='scss'>
*{
  box-sizing: border-box;
}
.dialogBox {
  ::v-deep .el-dialog {
    background: rgb(0, 92, 168) !important;
    border-radius: 8px;
    overflow: hidden;
    .el-dialog__header {
      .el-dialog__title {
        color: #fff !important;
        font-weight: 700;
        font-size: 1.4vw;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: rgb(51, 125, 185) !important;
        font-size: 1.5vw;
        font-weight: 700;
      }
    }
    .el-dialog__body {
      background: rgb(25, 56, 114) !important;
      color: #fff;
      padding: 15px !important;
      height: 400px;
      overflow: hidden;

      .contentBox {
        height: 100%;
        .contentBox_left {
          align-content: center;
          width: 100%;
          border: 1px dashed #ccc;
          border-radius: 8px;
          height: 100%;
          padding: 20px 10px;
          .contentBox_left_hang {
            margin-bottom: 0.8vw;
            font-size: 1vw;
            .el-col:nth-child(1) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
	<div class="wpbox">
		<div class="bnt">
			<div class="topbnt_left fl">
				<span>地区名称：</span>
				<el-select @change="deptChange($event)" v-model="deptId" filterable placeholder="请选择地区">
					<el-option v-for="item in options" :key="item.deptId" :label="item.deptName" :value="item.deptId">
					</el-option>
				</el-select>
			</div>
			<h1 class="tith1 fl">{{defineTitle}}垃圾分类大数据平台</h1>
			<div class="fr topbnt_right">
				<div class="laji" :class="[i == index ? 'active' : '']" v-for="(item, i) in lajiList" :key="i"
					@click="indexClick(i)">
					{{ item.name }}
				</div>
			</div>
		</div>
		<el-row class="bigBox" :gutter="5">
			<el-col class="bigBox_left" :span="6">
				<div class="bigBox_left_one">
					<div class="bigBox_tilte tow_title">垃圾分类小知识</div>
					<video id="myVideo" class="video-js vjs-big-play-centered vjs-fluid" controls autoplay loop muted
						width="100%" height="100%">
						<source type="video/mp4" :src="homeVideo" />
					</video>
				</div>
				<div class="bigBox_left_two">
					<div class="bigBox_tilte">倡导绿色分类</div>
					<!--					<img :src="homeImg" alt="" />-->
					<div id="video-container" @click="fullScreen" />
				</div>
				<div class="bigBox_left_three">
					<div class="bigBox_tilte tow_title">生活垃圾周趋势图</div>
					<div class="chartBox" id="chart1" />
				</div>
			</el-col>
			<el-col class="bigBox_center" :span="12">
				<div class="bigBox_center_one">
					<div class="bigBox_tilte">垃圾投放点收集情况</div>
					<!-- <div class="mapBox"> -->
					<div id="map-container" />
					<!-- </div> -->
				</div>
				<div class="bigBox_center_two">
					<div class="bigBox_tilte">今日信息统计</div>
					<div class="txt">
						<span>总户数：</span>
						<span>{{ totalHouseholds }}&nbsp;&nbsp;&nbsp;</span>
						<span>常驻户数：</span>
						<span>{{ residentHouseholds }}&nbsp;&nbsp;&nbsp;</span>
						<span>总人数：</span>
						<span>{{ totalPerson }}&nbsp;&nbsp;&nbsp;</span>
						<span>常驻人数：</span>
						<span>{{ residentPopulation }}&nbsp;&nbsp;&nbsp;</span>
						<span>参与户数：</span>
						<span>{{ numberOfParticipants }}</span>
					</div>
					<div class="flexBox">
						<!-- <div id="chart1_1" />
            <div id="chart1_2" /> -->
						<div id="chart2_1" />
						<div id="chart2_2" />
						<div id="chart2_3" />
					</div>
				</div>
			</el-col>
			<el-col class="bigBox_right" :span="6">
				<div class="bigBox_right_one">
					<div class="bigBox_tilte" v-if="!showEarlyWarningInfo">地区垃圾投放情况</div>
					<div class="bigBox_tilte" v-if="showEarlyWarningInfo">地区垃圾预警信息情况</div>
					<el-row class="zhtc_table_title" v-if="!showEarlyWarningInfo">
						<el-col class="deptName" :span="4">区县</el-col>
						<el-col class="deptName" :span="4">投放点</el-col>
						<!--            <el-col :span="4">地址</el-col>-->
						<el-col class="deptName" :span="4">重量</el-col>
						<el-col class="deptName" :span="4">图片</el-col>
						<el-col class="deptName" :span="4">时间</el-col>
					</el-row>
					<el-row class="zhtc_table_title" v-if="showEarlyWarningInfo">
						<el-col class="deptName" :span="4">设备号</el-col>
						<el-col class="deptName" :span="4">小区村</el-col>
						<el-col class="deptName" :span="4">异常</el-col>
						<el-col class="deptName" :span="4">处理时间</el-col>
						<el-col class="deptName" :span="4">状态</el-col>
						<el-col class="deptName" :span="4">处理人</el-col>
					</el-row>
					<input type="hidden" name="top_height" id="top_height" value="0" />
					<div id="zhtc_table" class="left2_table" v-if="!showEarlyWarningInfo">
						<el-row class="zhtc_table_li_content" id="zhtc_content" v-for="(item, i) in zhtcList2" :key="i"
							@click.native="lajiDetail(item)">
							<el-col :span="4">{{item.areaName ? item.areaName : "未知"}}</el-col>
							<el-col :span="4">{{item.communityVillageName ? item.communityVillageName : "未知"}}</el-col>
							<el-col :span="4">{{item.deliveryWeight ? item.deliveryWeight : "未知"}}</el-col>
							<el-col :span="4"><img width="60" :src="item.trashCanPhotos" /></el-col>
							<el-col :span="4">{{item.createTime? item.createTime.substring(5, 10): "未知"}}</el-col>
						</el-row>
					</div>
					<div id="zhtc_table" class="left2_table" v-if="showEarlyWarningInfo">
						<el-row class="zhtc_table_li_content" id="zhtc_content" v-for="(item, i) in zhtcList2" :key="i"
							@click.native="warningInfoDetail(item)">
							<el-col class="deptName" :span="4">
								{{item.realEquipmentNo ? item.realEquipmentNo.substring(0, 3)+"..." : "未知"}}
							</el-col>
							<el-col class="deptName" :span="4">{{item.deptName ? item.deptName : "未知"}}</el-col>
							<el-col class="deptName" :span="4">{{item.warningTypeStr ? item.warningTypeStr : "未知"}}
							</el-col>
							<el-col class="deptName" :span="4">
								{{item.warningTime? item.warningTime.substring(5, 10): "未知"}}
							</el-col>
							<el-col class="deptName" :span="4">{{item.treatmentStateStr ?item.treatmentStateStr : "未知"}}
							</el-col>
							<el-col class="deptName" :span="4">{{item.treatmentBy ? item.treatmentBy : "未知"}}</el-col>
						</el-row>
					</div>
				</div>
				<div class="bigBox_right_two">
					<div class="bigBox_tilte tow_title">个人积分统计情况</div>
					<el-row class="zhtc_table_title">
						<el-col class="deptName" :span="3">排名</el-col>
						<el-col class="deptName" :span="4">姓名</el-col>
						<el-col class="deptName" :span="7">手机号</el-col>
						<el-col class="deptName" v-if="screenWidth>1200" :span="6">所属区/县</el-col>
						<el-col class="deptName" v-else :span="6">区/县</el-col>
						<el-col class="deptName" :span="4">积分</el-col>
					</el-row>
					<div id="zhtc_table" class="left2_table _left2_tables">
						<el-row class="zhtc_table_li_content2" v-for="(item, i) in jifenList" :key="i"
							@click.native="userDetail">
							<el-col class="deptName" :span="2">{{ item.ranking||'未知' }}</el-col>
							<el-col class="deptName" :span="5">{{ item.realName||'未知'}}</el-col>
							<el-col class="deptName" :span="7">{{ item.phone|cellPhone}}</el-col>
							<el-col class="deptName" :span="6">{{ item.deptName||'未知'}}</el-col>
							<el-col class="deptName" :span="4">{{ item.integral||'未知' }}</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
		</el-row>
		<Dialog ref="dialogDetile"></Dialog>
		<WarningInfoDialog ref="warningInfoDetile"></WarningInfoDialog>
	</div>
</template>

<script>
import "@/js/moment.min.js";
import "@/js/ezuikit.js"
// import "@/js/智慧停车.js";
import * as echarts from "echarts";
import $ from "jquery";
import screenfull from "screenfull";
import Dialog from "@/components/dialog.vue";
import WarningInfoDialog from "@/components/warningInfoDialog.vue";
import {
	listDelivery, leftGarbageList,
	garbageDeliveryInHomeArea,
	collectionAtGarbageDeliveryPoint,
	regionSelector,
} from "@/api/business/delivery";
import {
	personalIntegralStatistics,
	listUser,
	login
} from "@/api/business/user";
import {
	listConfig,
	getConfigKey,
	getConfig,
	delConfig,
	addConfig,
	updateConfig,
	refreshCache,
} from "@/api/system/config";
import {
	listDept
} from "@/api/system/dept";
import {
	listWarning
} from "@/api/business/warning";
import { getToken, setToken } from "@/utils/auth";
export default {
	components: {
		Dialog,
		WarningInfoDialog,
	},
	data() {
		return {
			screenWidth: '',
			homeImg: "",
			homeVideo: "",
			map: null,
			index: 0,
			options: [],
			value: "",
			deptId: null,
			lajiList: [{
				name: "易腐垃圾",
			},
			{
				name: "其他垃圾",
			},
			{
				name: "可回收垃圾",
			},
			{
				name: "有害垃圾",
			},
			{
				name: "预警信息",
			},
			],
			zhtcList: [],
			zhtcList2: [],
			jifenList: [],
			timer: null,
			timers: null,
			timerArray: [],
			timerArrays: [],
			dateArray: [],
			dateArrayParams: [],
			perishableData: [],
			otherData: [],
			recyclableData: [],
			harmfulData: [],
			lastDate: null,
			totalHouseholds: 0, //总户数
			residentHouseholds: 0, //常驻户数
			totalPerson: 0,//总人数
			residentPopulation: 0,//常驻人数
			numberOfParticipants: 0, //参与户数
			showEarlyWarningInfo: false, //显示预警信息
			defineTitle: '绿能手', //自定义大标题
			playr: null,
		};
	},
	filters: {
		cellPhone(data) {
			let reg = /(?<=\d{3})(\d{4})/;
			return data.replace(reg, "****");
		}
	},
	created() {
		var token=getToken();
		if (!token) {
			login().then((res) => {
				if (res.code != 200) {

				}
				else {
					setToken(res.token);
				}
				this.getHomeImg();
				this.getHomeVideo();
				this.write()
				// 获取地区列表
				regionSelector().then((response) => {
					if (response.data.length < 1) return;
					if (response.data.length == 1) {
						this.defineTitle = response.data[0].deptName
					} else {
						this.defineTitle = '绿能手';
					}
					// 小区列表
					this.options = response.data;
					this.options.forEach((v) => {
					});
				});
				this.$nextTick(() => {
					$(".wpbox").height(document.documentElement.clientHeight - 50);
					$("#app").height(document.documentElement.clientHeight);
					this.getList();
					this.getMap();
					this.getOption1();
					// this.getOption2_1();
					this.getOption2_3();
					this.getScroll();
					this.getScroll1();
				});
			})
		}else{
			this.getHomeImg();
				this.getHomeVideo();
				this.write()
				// 获取地区列表
				regionSelector().then((response) => {
					if (response.data.length < 1) return;
					if (response.data.length == 1) {
						this.defineTitle = response.data[0].deptName
					} else {
						this.defineTitle = '绿能手';
					}
					// 小区列表
					this.options = response.data;
					this.options.forEach((v) => {
					});
				});
				this.$nextTick(() => {
					$(".wpbox").height(document.documentElement.clientHeight - 50);
					$("#app").height(document.documentElement.clientHeight);
					this.getList();
					this.getMap();
					this.getOption1();
					// this.getOption2_1();
					this.getOption2_3();
					this.getScroll();
					this.getScroll1();
				});
		}


	},

	mounted() {
		let that = this;
		window.addEventListener("resize", function () {
			$(".wpbox").height(document.documentElement.clientHeight - 50);
			$("#app").height(document.documentElement.clientHeight);
			// that.getMap();
		});
	},

	methods: {
		write() {
			this.screenWidth = window.screen.width
			if (window.screen.width < 1200) {
				this.$nextTick(v => {
					$(".wpbox").height(document.documentElement.clientHeight - 50);
					$("#app").height(document.documentElement.clientHeight);
					this.getOption2_1_1()
				})
			} else if (window.screen.width > 1200) {
				this.$nextTick(v => {
					$(".wpbox").height(document.documentElement.clientHeight - 50);
					$("#app").height(document.documentElement.clientHeight);
					this.getOption2_1()
				})
			}
		},
		// 选择小区
		deptChange(e) {
			this.deptId = e;
			this.getMapByDeptId(); //没问题
			this.getList();
			this.getOption1();
			this.getOption2_1();
			this.getOption2_2();
			this.getOption2_3();
			this.getScroll('deptChange')
			this.getScroll1('deptChange')
			// this.getScroll1()
		},
		add0(m) {
			return m < 10 ? '0' + m : m
		},
		formatDataToStr(time) {
			var y = time.getFullYear();
			var m = time.getMonth() + 1;
			var d = time.getDate();
			var h = time.getHours();
			var mm = time.getMinutes();
			var s = time.getSeconds();
			//return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
			return y + '-' + this.add0(m) + '-' + this.add0(d);
		},
		// 获取数据列表
		getList() {
			//个人积分统计情况
			personalIntegralStatistics({
				deptId: this.deptId
			}).then((response) => {
				this.jifenList = response.data;
			});
			garbageDeliveryInHomeArea({
				deliveryCellId: this.deptId,
				garbageCategory: "1",
			}).then((res) => {
				this.zhtcList2 = [];
				if (res.data.length > 0) {
					this.zhtcList2 = res.data;
				}
			});
			// 参与户数
			listDelivery({
				deliveryCellId: this.deptId
			}).then((response) => {
				//根据delivery_user_id去重
				const res = new Map();
				var nums = 0;
				var rows = response.rows;
				for (var i = 0; i < rows.length; i++) {
					if (rows[i].deliveryUserId != null) {
						if (rows[i].score !== '0') {
							continue;
						}
						if (rows[i].deliveryUserId === '888888888888888888') {
							nums = nums + 1;
						} else {
							if (!res.has(rows[i].deliveryUserId)) {
								res.set(rows[i].deliveryUserId, 1);
								nums = nums + 1;
							}
						}
					}
				}
				this.numberOfParticipants = nums;
				this.getOption2_2();
			});
			// 总户数
			listDept({
				deptId: this.deptId
			}).then((response) => {
				if (response.data.length < 1) return;
				//取出部门列表里部门
				var deptList = response.data;
				var total = 0;
				var total2 = 0;
				var total3 = 0;
				for (var i = 0; i < deptList.length; i++) {
					// 总用户数
					if (deptList[i].totalHouseholds && deptList[i].level === 6) {
						total = total + parseInt(deptList[i].totalHouseholds);
					}
					//常驻户数
					if (deptList[i].residentHouseholds && deptList[i].level === 6) {
						total2 = total2 + parseInt(deptList[i].residentHouseholds);
					}
					//常驻人数
					if (deptList[i].residentPopulation) {
						total3 = total3 + parseInt(deptList[i].residentPopulation);
					}
				}
				this.residentPopulation = total3;
				this.residentHouseholds = total2;
				this.totalHouseholds = total;
				this.getOption2_2();
			});
			// 总人数
			listUser({
				deptId: this.deptId
			}).then((response) => {
				this.totalPerson = response.total;
			});
		},
		getHomeImg() {
			getConfigKey("home_img").then((res) => {
				this.homeImg = res.msg;
			});
		},
		getHomeVideo() {
			getConfigKey("home_video").then((res) => {
				this.homeVideo = res.msg;

				let vdo = document.getElementById("myVideo");
				vdo.src = res.msg;
				vdo.play();
			});
		},
		getMap() {
			this.map = new BMapGL.Map("map-container");
			this.map.setMapType(BMAP_EARTH_MAP); // 设置地图类型为地球模式
			this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
			var _this = this;
			collectionAtGarbageDeliveryPoint({
				deptId: this.deptId
			}).then((response) => {
				var equipment = response.equipment;
				if (equipment.length > 1) {
					_this.map.centerAndZoom(new BMapGL.Point(103.388611, 35.563611), 5);
				}
				var data = [];
				for (var i = 0; i < equipment.length; i++) {
					var deptName = equipment[i].deptName;
					var parentName = equipment[i].address;
					var perishableGarbageHouseholds = equipment[i].perishableGarbageHouseholds;
					var recyclableGarbageHouseholds = equipment[i].recyclableGarbageHouseholds;
					var otherGarbageHouseholds = equipment[i].otherGarbageHouseholds;
					var harmfulGarbageHouseholds = equipment[i].harmfulGarbageHouseholds;
					if (deptName != null && deptName != '' && parentName != null && parentName != '') {
						data.push({
							"deptName": deptName, "parentName": parentName,
							"perishableGarbageHouseholds": perishableGarbageHouseholds,
							"recyclableGarbageHouseholds": recyclableGarbageHouseholds,
							"otherGarbageHouseholds": otherGarbageHouseholds,
							"harmfulGarbageHouseholds": harmfulGarbageHouseholds
						});
					}
				}
				//创建地址解析器实例
				var myGeo = new BMapGL.Geocoder();
				var pointList = [];
				function getName(i) {
					if (i < data.length) {
						var deptName = data[i].deptName;
						var parentName = data[i].parentName;
						var perishableGarbageHouseholds = data[i].perishableGarbageHouseholds;
						var recyclableGarbageHouseholds = data[i].recyclableGarbageHouseholds;
						var otherGarbageHouseholds = data[i].otherGarbageHouseholds;
						var harmfulGarbageHouseholds = data[i].harmfulGarbageHouseholds;
						myGeo.getPoint(parentName, function (point) {
							i++
							getName(i)
							if (point) {
								pointList.push(new BMapGL.Point(point.lng, point.lat));
								if (equipment.length === 1) {
									_this.map.centerAndZoom(new BMapGL.Point(point.lng, point.lat), 19);
								}
								var marker1 = new BMapGL.Marker(new BMapGL.Point(point.lng, point.lat));
								_this.map.addOverlay(marker1);
								// 创建信息窗口
								var opts = {
									width: 200,
									height: 100,
									title: deptName + '   今日收集情况',
								};
								let content = ``;
								content += `易腐垃圾：` + perishableGarbageHouseholds + `户<br/>`;
								content += `可回收垃圾：` + recyclableGarbageHouseholds + `户<br/>`;
								content += `其他垃圾：` + otherGarbageHouseholds + `户<br/>`;
								content += `有害垃圾：` + harmfulGarbageHouseholds + `户<br/>`;

								let infoWindow = new BMapGL.InfoWindow(content, opts);
								// 点标记添加点击事件
								marker1.addEventListener("click", function () {
									_this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
								});
							} else {
								//alert('您选择的地址没有解析到结果！');
							}
						}, '中国');
					}
				}
				getName(0)
			});
		},
		getMapByDeptId() {
			var _this = this;
			collectionAtGarbageDeliveryPoint({
				deptId: this.deptId
			}).then((response) => {
				var equipment = response.equipment;
				var data = [];
				for (var i = 0; i < equipment.length; i++) {
					this.getEzuikit(equipment[i].ezuikitUrl);
					var deptName = equipment[i].deptName;
					var parentName = equipment[i].address;
					var perishableGarbageHouseholds = equipment[i].perishableGarbageHouseholds;
					var recyclableGarbageHouseholds = equipment[i].recyclableGarbageHouseholds;
					var otherGarbageHouseholds = equipment[i].otherGarbageHouseholds;
					var harmfulGarbageHouseholds = equipment[i].harmfulGarbageHouseholds;
					if (deptName != null && deptName != '' && parentName != null && parentName != '') {
						data.push({
							"deptName": deptName, "parentName": parentName,
							"perishableGarbageHouseholds": perishableGarbageHouseholds,
							"recyclableGarbageHouseholds": recyclableGarbageHouseholds,
							"otherGarbageHouseholds": otherGarbageHouseholds,
							"harmfulGarbageHouseholds": harmfulGarbageHouseholds
						});
					}
				}
				//创建地址解析器实例
				var myGeo = new BMapGL.Geocoder();
				var pointList = [];
				function getName(i) {
					if (i < data.length) {
						var deptName = data[i].deptName;
						var parentName = data[i].parentName;
						var perishableGarbageHouseholds = data[i].perishableGarbageHouseholds;
						var recyclableGarbageHouseholds = data[i].recyclableGarbageHouseholds;
						var otherGarbageHouseholds = data[i].otherGarbageHouseholds;
						var harmfulGarbageHouseholds = data[i].harmfulGarbageHouseholds;
						myGeo.getPoint(parentName, function (point) {
							i++
							getName(i)
							if (point) {
								pointList.push(new BMapGL.Point(point.lng, point.lat));
								var view = _this.map.getViewport(eval(pointList));
								var mapZoom = view.zoom;
								var centerPoint = view.center;
								_this.map.centerAndZoom(new BMapGL.Point(point.lng, point.lat), 19);
								//_this.map.centerAndZoom(new BMapGL.Point(point.lng, point.lat), 13);
								var marker1 = new BMapGL.Marker(new BMapGL.Point(point.lng, point.lat));
								_this.map.addOverlay(marker1);
								// 创建信息窗口
								var opts = {
									width: 200,
									height: 100,
									title: deptName + '   今日收集情况',
								};
								let content = ``;
								content += `易腐垃圾：` + perishableGarbageHouseholds + `户<br/>`;
								content += `可回收垃圾：` + recyclableGarbageHouseholds + `户<br/>`;
								content += `其他垃圾：` + otherGarbageHouseholds + `户<br/>`;
								content += `有害垃圾：` + harmfulGarbageHouseholds + `户<br/>`;

								let infoWindow = new BMapGL.InfoWindow(content, opts);
								// 点标记添加点击事件
								marker1.addEventListener("click", function () {
									_this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
								});
							} else {
								//alert('您选择的地址没有解析到结果！');
							}
						}, '中国');
					}
				}
				getName(0)
			});
		},
		indexClick(i) {
			this.index = i;
			this.showEarlyWarningInfo = false;
			var garbageCategory;
			if (i == 0) {
				//易腐
				garbageCategory = "1";
			} else if (i == 1) {
				//其他垃圾
				garbageCategory = "3";
			} else if (i == 2) {
				//可回收
				garbageCategory = "2";
			} else if (i == 3) {
				//有害
				garbageCategory = "4";
			} else if (i == 4) {
				this.showEarlyWarningInfo = true;
			}
			this.zhtcList2 = [];
			if (this.showEarlyWarningInfo == false) {
				garbageDeliveryInHomeArea({
					deliveryCellId: this.deptId,
					garbageCategory: garbageCategory,
				}).then((res) => {
					if (res.data.length > 0) {
						this.zhtcList2 = res.data;
					}
				});
			} else if (this.showEarlyWarningInfo == true) {
				//地区预警信息
				listWarning({
					deptId: this.deptId,
					pageSize: 100,
					pageNum: 1
				}).then(response => {
					this.zhtcList2 = response.rows;
				});
			}
		},
		getDay(day) {
			var today;
			if (this.dateArray.length == 0) {
				today = new Date();
				//将今日也算上
				const nowDate = new Date();
				const todayTime = nowDate.getTime();
				nowDate.setTime(todayTime);
				const toYear = nowDate.getFullYear();
				let toMonth = nowDate.getMonth();
				let toDate = nowDate.getDate();
				toMonth = this.doHandleMonth(toMonth + 1);
				toDate = this.doHandleMonth(toDate);
				this.dateArray.push(toMonth + "-" + toDate);
				this.dateArrayParams.push(toYear + "-" + toMonth + "-" + toDate);
			} else {
				today = this.lastDate;
			}
			this.lastDate = today;
			const targetday = today.getTime() + 1000 * 60 * 60 * 24 * day;
			today.setTime(targetday);
			const tYear = today.getFullYear();
			let tMonth = today.getMonth();
			let tDate = today.getDate();
			tMonth = this.doHandleMonth(tMonth + 1);
			tDate = this.doHandleMonth(tDate);
			this.dateArray.push(tMonth + "-" + tDate);
			this.dateArrayParams.push(tYear + "-" + tMonth + "-" + tDate);
			return this.dateArray;
		},
		doHandleMonth(month) {
			var m = month;
			if (month.toString().length === 1) {
				m = "0" + month;
			}
			return m;
		},
		dateToStr(date) {
			var year = date.getFullYear(); //获取完整的年份(4位,1970-????)
			var month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
			var day = date.getDate(); //获取当前日(1-31)
			if (month < 10) {
				month = "0" + month;
			}
			if (day < 10) {
				day = "0" + day;
			}
			return year + "-" + month + "-" + day;
		},
		instantiatedHistogram() {
			if (this.dateArrayParams.length !== 0) {
				var dateArray = this.dateArrayParams;
				var _this = this;
				leftGarbageList({
					deliveryTimeBegin: this.dateArrayParams[4],
					deliveryTimeEnd: this.dateArrayParams[0],
					deliveryCellId: this.deptId,
				}).then((response) => {
					//先获取对应日期的数据
					var perishableNum0 = 0,
						perishableNum1 = 0,
						perishableNum2 = 0,
						perishableNum3 = 0,
						perishableNum4 = 0; //易腐
					var recyclableNum0 = 0,
						recyclableNum1 = 0,
						recyclableNum2 = 0,
						recyclableNum3 = 0,
						recyclableNum4 = 0; //可回收
					var otherNum0 = 0,
						otherNum1 = 0,
						otherNum2 = 0,
						otherNum3 = 0,
						otherNum4 = 0; //其他
					var harmfulNum0 = 0,
						harmfulNum1 = 0,
						harmfulNum2 = 0,
						harmfulNum3 = 0,
						harmfulNum4 = 0; //有害
					_this.perishableData = [];
					_this.recyclableData = [];
					_this.otherData = [];
					_this.harmfulData = [];
					for (var i = 0; i < response.rows.length; i++) {
						var deliveryTimeFormat = response.rows[i].createTime.substr(0, 10);
						if (deliveryTimeFormat === dateArray[0]) {
							if (response.rows[i].garbageCategory === "1") {
								perishableNum0 = perishableNum0 + 1;
							} else if (response.rows[i].garbageCategory === "2") {
								recyclableNum0 = recyclableNum0 + 1;
							} else if (response.rows[i].garbageCategory === "3") {
								otherNum0 = otherNum0 + 1;
							} else if (response.rows[i].garbageCategory === "4") {
								harmfulNum0 = harmfulNum0 + 1;
							}
						} else if (deliveryTimeFormat === dateArray[1]) {
							if (response.rows[i].garbageCategory === "1") {
								perishableNum1 = perishableNum1 + 1;
							} else if (response.rows[i].garbageCategory === "2") {
								recyclableNum1 = recyclableNum1 + 1;
							} else if (response.rows[i].garbageCategory === "3") {
								otherNum1 = otherNum1 + 1;
							} else if (response.rows[i].garbageCategory === "4") {
								harmfulNum1 = harmfulNum1 + 1;
							}
						} else if (deliveryTimeFormat === dateArray[2]) {
							if (response.rows[i].garbageCategory === "1") {
								perishableNum2 = perishableNum2 + 1;
							} else if (response.rows[i].garbageCategory === "2") {
								recyclableNum2 = recyclableNum2 + 1;
							} else if (response.rows[i].garbageCategory === "3") {
								otherNum2 = otherNum2 + 1;
							} else if (response.rows[i].garbageCategory === "4") {
								harmfulNum2 = harmfulNum2 + 1;
							}
						} else if (deliveryTimeFormat === dateArray[3]) {
							if (response.rows[i].garbageCategory === "1") {
								perishableNum3 = perishableNum3 + 1;
							} else if (response.rows[i].garbageCategory === "2") {
								recyclableNum3 = recyclableNum3 + 1;
							} else if (response.rows[i].garbageCategory === "3") {
								otherNum3 = otherNum3 + 1;
							} else if (response.rows[i].garbageCategory === "4") {
								harmfulNum3 = harmfulNum3 + 1;
							}
						} else if (deliveryTimeFormat === dateArray[4]) {
							if (response.rows[i].garbageCategory === "1") {
								perishableNum4 = perishableNum4 + 1;
							} else if (response.rows[i].garbageCategory === "2") {
								recyclableNum4 = recyclableNum4 + 1;
							} else if (response.rows[i].garbageCategory === "3") {
								otherNum4 = otherNum4 + 1;
							} else if (response.rows[i].garbageCategory === "4") {
								harmfulNum4 = harmfulNum4 + 1;
							}
						}
					}
					_this.perishableData.push(perishableNum4);
					_this.perishableData.push(perishableNum3);
					_this.perishableData.push(perishableNum2);
					_this.perishableData.push(perishableNum1);
					_this.perishableData.push(perishableNum0);
					_this.recyclableData.push(recyclableNum4);
					_this.recyclableData.push(recyclableNum3);
					_this.recyclableData.push(recyclableNum2);
					_this.recyclableData.push(recyclableNum1);
					_this.recyclableData.push(recyclableNum0);
					_this.otherData.push(otherNum4);
					_this.otherData.push(otherNum3);
					_this.otherData.push(otherNum2);
					_this.otherData.push(otherNum1);
					_this.otherData.push(otherNum0);
					_this.harmfulData.push(harmfulNum4);
					_this.harmfulData.push(harmfulNum3);
					_this.harmfulData.push(harmfulNum2);
					_this.harmfulData.push(harmfulNum1);
					_this.harmfulData.push(harmfulNum0);
					let myChart = echarts.init(document.getElementById("chart1"));
					let option = {
						splitArea: {
							show: true,
							areaStyle: {
								color: ["blue"],
							},
						},
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "shadow",
							},
						},
						legend: {
							textStyle: {
								color: "#ffffff",
							},
						},
						grid: {
							left: "3%",
							right: "4%",
							bottom: "3%",
							containLabel: true,
						},
						xAxis: [{
							type: "category",
							// data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
							data: this.dateArray,
							axisTick: {
								alignWithLabel: true,
							},
							axisLine: {
								lineStyle: {
									color: "#ffffff",
									width: 1, //这里是为了突出显示加上的
								},
							},
						},],
						yAxis: [{
							type: "value",
							axisTick: {
								alignWithLabel: true,
							},
							axisLine: {
								lineStyle: {
									color: "#ffffff",
									width: 1, //这里是为了突出显示加上的
								},
							},
						},],
						series: [{
							name: "易腐",
							type: "line",
							smooth: true,
							// areaStyle: {},
							itemStyle: {
								//柱图背景色
								color: "#00aa00",
							},
							emphasis: {
								focus: "series",
							},
							// data: [320, 332, 301, 334, 390, 330, 320],
							data: this.perishableData,
						},
						{
							name: "其他",
							type: "line",
							smooth: true,
							// areaStyle: {},
							itemStyle: {
								//柱图背景色
								color: "#ffff00",
							},
							emphasis: {
								focus: "series",
							},
							// data: [120, 132, 101, 134, 90, 230, 210],
							data: this.otherData,
						},
						{
							name: "可回收",
							type: "line",
							smooth: true,
							// areaStyle: {},
							itemStyle: {
								//柱图背景色
								color: "#0055ff",
							},
							emphasis: {
								focus: "series",
							},
							// data: [220, 182, 191, 234, 290, 330, 310],
							data: this.recyclableData,
						},
						{
							name: "有害",
							type: "line",
							smooth: true,
							// areaStyle: {},
							itemStyle: {
								//柱图背景色
								color: "#aa0000",
							},
							emphasis: {
								focus: "series",
							},
							// data: [150, 232, 201, 154, 190, 330, 410],
							data: this.harmfulData,
						},
						],
					};

					myChart.setOption(option);
					window.addEventListener("resize", function () {
						myChart.resize();
					});
				});
			}
		},
		getScroll1(e) {
			if (e === 'deptChange') {
				for (var i = 0; i < this.timerArrays.length; i++) {
					clearInterval(this.timerArrays[i]);
				}
				this.timerArrays = [];
			}
			this.timers = setInterval(function () {
				document.getElementsByClassName("_left2_tables")[0].scrollTop += 1;
				// 184
				let a = document.getElementsByClassName("_left2_tables")[0].clientHeight;
				// 0
				let b = document.getElementsByClassName("_left2_tables")[0].scrollTop;
				// 184
				let c = document.getElementsByClassName("_left2_tables")[0].scrollHeight;

				if (a + b + 10 > c) {
					document.getElementsByClassName("_left2_tables")[0].scrollTop = 0;
				}
			}, 10);
			this.timerArrays.push(this.timers);

			//鼠标移上去停止滚动
			$("._left2_tables").mouseenter((res) => {
				for (var i = 0; i < this.timerArrays.length; i++) {
					clearInterval(this.timerArrays[i]);
				}
				this.timerArrays = [];
			});
			$("._left2_tables").mouseleave((res) => {
				for (var i = 0; i < this.timerArrays.length; i++) {
					clearInterval(this.timerArrays[i]);
				}
				this.timerArrays = [];
				//判断第二次不开启计时器
				//获取历史滚动条高度
				var top_height = document.getElementById("top_height").value;
				//获取form_hideen的高度
				// var a = document.getElementById("zhtc_content").scrollHeight;
				var a = document.getElementsByClassName("_left2_tables")[0].clientHeight;
				//获取form的高度
				var c = document.getElementsByClassName("_left2_tables")[0].scrollHeight;

				// if((parseInt(top_height) + parseInt(a)) < parseInt(c)){
				this.timers = setInterval((res) => {
					//获取滚动条停止前的高度
					// var top_height = document.getElementById('top_height').value;

					//每0.1秒滚动10像素
					// document.getElementById("zhtc_table").scrollTop += 1;
					document.getElementsByClassName("_left2_tables")[0].scrollTop += 1;

					//滚动条高度,记录到隐藏域
					document.getElementById("top_height").value =
						document.getElementsByClassName("_left2_tables")[0].scrollTop;

					//获取滚动条高度
					var form_scrollTop = document.getElementsByClassName("_left2_tables")[0].scrollTop;

					//判断滚动条是否到底,到底了就清除计时器
					if (parseInt(form_scrollTop) + parseInt(a) + 10 > parseInt(c)) {
						document.getElementsByClassName("_left2_tables")[0].scrollTop = 0;
						// clearInterval(timer);
						// document.getElementById('top_height').value = 0;
					}
				}, 10);
				this.timerArrays.push(this.timers);
				// }
			});
		},
		getScroll(e) {
			if (e === 'deptChange') {
				for (var i = 0; i < this.timerArray.length; i++) {
					clearInterval(this.timerArray[i]);
				}
				this.timerArray = [];
			}
			let long = this.zhtcList.length
			if (this.zhtcList.length > 6) {
				this.zhtcList = this.zhtcList.concat(this.zhtcList);
			}
			this.timer = setInterval(function () {
				document.getElementById("zhtc_table").scrollTop += 1;
				// let a = document.getElementById("zhtc_content").scrollHeight;
				let a = document.getElementById("zhtc_table").clientHeight;
				let b = document.getElementById("zhtc_table").scrollTop;
				let c = document.getElementById("zhtc_table").scrollHeight;

				if (a + b + 10 > c) {
					document.getElementById("zhtc_table").scrollTop = 0;
				}
			}, 10);
			this.timerArray.push(this.timer);

			//鼠标移上去停止滚动
			$("#zhtc_table").mouseenter((res) => {
				for (var i = 0; i < this.timerArray.length; i++) {
					clearInterval(this.timerArray[i]);
				}
				this.timerArray = [];
			});
			$("#zhtc_table").mouseleave((res) => {
				for (var i = 0; i < this.timerArray.length; i++) {
					clearInterval(this.timerArray[i]);
				}
				this.timerArray = [];
				//判断第二次不开启计时器
				//获取历史滚动条高度
				var top_height = document.getElementById("top_height").value;
				//获取form_hideen的高度
				// var a = document.getElementById("zhtc_content").scrollHeight;
				var a = document.getElementById("zhtc_table").clientHeight;
				//获取form的高度
				var c = document.getElementById("zhtc_table").scrollHeight;

				// if((parseInt(top_height) + parseInt(a)) < parseInt(c)){
				this.timer = setInterval((res) => {
					//获取滚动条停止前的高度
					// var top_height = document.getElementById('top_height').value;

					//每0.1秒滚动10像素
					// document.getElementById("zhtc_table").scrollTop += 1;
					document.getElementById("zhtc_table").scrollTop += 1;

					//滚动条高度,记录到隐藏域
					document.getElementById("top_height").value =
						document.getElementById("zhtc_table").scrollTop;

					//获取滚动条高度
					var form_scrollTop = document.getElementById("zhtc_table").scrollTop;


					//判断滚动条是否到底,到底了就清除计时器

					if (parseInt(form_scrollTop) + parseInt(a) + 10 > parseInt(c)) {
						document.getElementById("zhtc_table").scrollTop = 0;
						// clearInterval(timer);
						// document.getElementById('top_height').value = 0;
					}
				}, 10);
				this.timerArray.push(this.timer);
				// }
			});
		},
		lajiDetail(item) {
			this.$refs.dialogDetile.openClick(item);
		},
		warningInfoDetail(item) {
			this.$refs.warningInfoDetile.openClick(item);
		},
		userDetail() { },

		click() { },

		// 柱状图
		getOption1() {
			this.dateArray = [];
			for (var i = 0; i < 5; i++) {
				if (this.dateArray.length < 5) {
					this.dateArray = this.getDay(-1);
				}
			}
			this.dateArray.reverse();
			//实例化柱状图
			this.instantiatedHistogram();
		},
		getOption1_1() {
			var myChart = echarts.init(document.getElementById("chart1_1"));

			var myChart = echarts.init(document.getElementById("chart1_1"));
			listDelivery({
				deliveryCellId: this.deptId
			}).then((response) => {
				var perishableWeight = 0;
				var totalWeight = 0;
				for (var i = 0; i < response.rows.length; i++) {
					if (
						response.rows[i].deliveryWeight &&
						response.rows[i].garbageCategory === "0"
					) {
						perishableWeight =
							perishableWeight + parseFloat(response.rows[i].deliveryWeight);
					}
					if (response.rows[i].deliveryWeight) {
						totalWeight =
							totalWeight + parseFloat(response.rows[i].deliveryWeight);
					}
				}
				var perishableWeightRate = [{
					value: perishableWeight / totalWeight,
					name: "Direct"
				},
				{
					value: 1 - perishableWeight / totalWeight,
					name: "Email"
				},
				];
				var perishableWeightText = perishableWeight + "kg";
				var option = {
					title: {
						text: "易腐重量",
						subtext: perishableWeightText,
						left: "center",
						textStyle: {
							color: "#ffffff",
						},
						bottom: "30%",
					},
					color: ["aqua", "#142334"],
					// legend: {
					//   orient: "vertical",
					//   left: "left",
					// },
					series: [{
						type: "pie",
						radius: "60%",
						label: {
							show: false,
						},
						data: perishableWeightRate,
						center: ["50%", "30%"], //这个属性调整图像的位置
					},],
				};
				myChart.setOption(option);
				window.addEventListener("resize", function () {
					myChart.resize();
				});
			});
		},
		getOption1_2() {
			var myChart = echarts.init(document.getElementById("chart1_2"));

			var myChart = echarts.init(document.getElementById("chart1_2"));
			listDelivery({
				deliveryCellId: this.deptId
			}).then((response) => {
				var perishableWeight = 0;
				var totalWeight = 0;
				for (var i = 0; i < response.rows.length; i++) {
					if (
						response.rows[i].deliveryWeight &&
						response.rows[i].garbageCategory === "1"
					) {
						perishableWeight =
							perishableWeight + parseFloat(response.rows[i].deliveryWeight);
					}
					if (response.rows[i].deliveryWeight) {
						totalWeight =
							totalWeight + parseFloat(response.rows[i].deliveryWeight);
					}
				}
				var perishableWeightRate = [{
					value: perishableWeight / totalWeight,
					name: "Direct"
				},
				{
					value: 1 - perishableWeight / totalWeight,
					name: "Email"
				},
				];
				var perishableWeightText = perishableWeight + "kg";
				var option = {
					title: {
						text: "可回收重量",
						subtext: perishableWeightText,
						left: "center",
						textStyle: {
							color: "#ffffff",
						},
						bottom: "30%",
					},
					color: ["#FF00FF", "#142334"],
					// legend: {
					//   orient: "vertical",
					//   left: "left",
					// },
					series: [{
						type: "pie",
						radius: "60%",
						label: {
							show: false,
						},
						data: perishableWeightRate,
						center: ["50%", "30%"], //这个属性调整图像的位置
					},],
				};
				myChart.setOption(option);
				window.addEventListener("resize", function () {
					myChart.resize();
				});
			});
		},
		// 柱状图
		getOption2_1() {
			var myChart = echarts.init(document.getElementById("chart2_1"));
			listDelivery({
				deliveryCellId: this.deptId
			}).then((response) => {
				var perishableWeight = 0;
				var totalWeight = 0;
				// 易腐
				let a = 0;
				// 可回收
				let b = 0;
				// 其他
				let c = 0;
				//有害
				let d = 0;
				for (var i = 0; i < response.rows.length; i++) {
					totalWeight = parseInt(totalWeight) + parseInt(response.rows[i].deliveryWeight);
					if (!response.rows[i].deliveryWeight) return;
					switch (response.rows[i].garbageCategory) {
						case "1":
							a = a + parseInt(response.rows[i].deliveryWeight);
							break;
						case "2":
							b = b + parseInt(response.rows[i].deliveryWeight);
							break;
						case "3":
							c = c + parseInt(response.rows[i].deliveryWeight);
							break;
						case "4":
							d = d + parseInt(response.rows[i].deliveryWeight);
							break;
						default:
							break;
					}
				}
				a = a / 1000;
				b = b / 1000;
				c = c / 1000;
				d = d / 1000;
				if (totalWeight) {
					totalWeight = totalWeight / 1000;
				}
				var option = {
					title: {
						text: "垃圾重量(kg):" + totalWeight,
						textStyle: {
							color: "#ffffff",
						},
					},
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
					},
					legend: {
						textStyle: {
							color: "#ffffff",
						},
					},
					grid: {
						left: "3%",
						right: "4%",
						bottom: "25%",
						containLabel: true,
					},
					xAxis: [{
						type: "category",
						data: ["易腐", "可回收", "其他", "有害"],
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							lineStyle: {
								color: "#ffffff",
								width: 1, //这里是为了突出显示加上的
							},
						},
					},],
					yAxis: [{
						type: "value",
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							lineStyle: {
								color: "#ffffff",
								width: 1, //这里是为了突出显示加上的
							},
						},
					},],
					series: [{
						data: [{
							value: a,
							itemStyle: {
								color: "#00aa00",
							},
						},
						{
							value: b,
							itemStyle: {
								color: "#0055ff",
							},
						},
						{
							value: c,
							itemStyle: {
								color: "#ffff00",
							},
						},
						{
							value: d,
							itemStyle: {
								color: "#aa0000",
							},
						},
						],
						type: "bar",
						barWidth: "50%", //设置柱状图的柱状的宽度
						label: {
							show: true,
							position: "inside",
							color: "#ffffff",
							fontWeight: "lighter",
							position: 'top'
						},
					},],
				};
				myChart.setOption(option);
				window.addEventListener("resize", function () {
					myChart.resize();
				});
			});
		},
		getOption2_1_1() {
			var myChart = echarts.init(document.getElementById("chart2_1"));
			listDelivery({
				deliveryCellId: this.deptId
			}).then((response) => {
				var perishableWeight = 0;
				var totalWeight = 0;
				// 易腐
				let a = 0;
				// 可回收
				let b = 0;
				// 其他
				let c = 0;
				for (var i = 0; i < response.rows.length; i++) {
					totalWeight = parseInt(totalWeight) + parseInt(response.rows[i].deliveryWeight);
					if (!response.rows[i].deliveryWeight) return;
					switch (response.rows[i].garbageCategory) {
						case "0":
							a++;
							break;
						case "1":
							b++;
							break;
						case "2":
							c++;
							break;
						default:
							break;
					}
				}
				if (totalWeight) {
					totalWeight = totalWeight / 1000;
				}
				var option = {
					title: {
						text: "垃圾重量(kg):" + totalWeight,
						textStyle: {
							color: "#ffffff",
							fontSize: '12px',
						},
					},
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
					},
					legend: {
						textStyle: {
							color: "#ffffff",
						},
					},
					grid: {
						left: "3%",
						right: "4%",
						bottom: "25%",
						containLabel: true,
					},
					xAxis: [{
						type: "category",
						data: ["易腐", "可回收", "其他"],
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							lineStyle: {
								color: "#ffffff",
								width: 1, //这里是为了突出显示加上的
							},
						},
					},],
					yAxis: [{
						type: "value",
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							lineStyle: {
								color: "#ffffff",
								width: 1, //这里是为了突出显示加上的
							},
						},
					},],
					series: [{
						data: [{
							value: a,
							itemStyle: {
								color: "#800080",
							},
						},
						{
							value: b,
							itemStyle: {
								color: "#a90000",
							},
						},
						{
							value: c,
							itemStyle: {
								color: "#1E90FF",
							},
						},
						],
						type: "bar",
						barWidth: "50%", //设置柱状图的柱状的宽度
						label: {
							show: true,
							position: "inside",
							color: "#fff",
							fontWeight: "lighter",
						},
					},],
				};
				myChart.setOption(option);
				window.addEventListener("resize", function () {
					myChart.resize();
				});
			});
		},
		// 饼状图
		getOption2_2() {
			var myChart = echarts.init(document.getElementById("chart2_2"));
			var participationRate = [{
				value: this.numberOfParticipants / this.residentHouseholds,
				name: "Direct",
			},
			{
				value: 1 - this.numberOfParticipants / this.residentHouseholds,
				name: "Email",
			},
			];
			var participationText = Math.floor((this.numberOfParticipants / this.residentHouseholds) * 100) + "%";
			if (participationText === 'NaN%') {
				participationText = 0 + '%'
			}
			var option = {
				title: {
					text: "参与率",
					subtext: participationText,
					left: "center",
					textStyle: {
						color: "#ffffff",
					},
					bottom: "30%",
				},
				// legend: {
				// },
				color: ["#FFFF00", "#142334"],
				// legend: {
				//   orient: "vertical",
				//   left: "left",
				// },
				series: [{
					type: "pie",
					radius: "60%",
					label: {
						show: false,
					},
					data: participationRate,
					center: ["50%", "30%"], //这个属性调整图像的位置
				},],
			};
			myChart.setOption(option);
			window.addEventListener("resize", function () {
				myChart.resize();
			});
		},
		getOption2_3() {
			var myChart = echarts.init(document.getElementById("chart2_3"));
			listDelivery({
				deliveryCellId: this.deptId
			}).then((response) => {
				let size = 0;
				var rows = response.rows;
				for (var i = 0; i < rows.length; i++) {
					if (rows[i].score === '0') {
						size = size + 1;
					}
				}
				var correctRate = [{
					value: size / rows.length,
					name: "Direct"
				},
				{
					value: 1 - size / rows.length,
					name: "Email"
				},
				];
				var currentText =
					Math.floor((size / rows.length) * 100) + "%";
				if (currentText == 'NaN%') {
					currentText = 0 + '%'
				}
				var option = {
					title: {
						text: "合格率",
						subtext: currentText,
						left: "center",
						textStyle: {
							color: "#ffffff",
						},
						bottom: "30%",
					},
					// legend: {
					// },
					color: ["#00FF00", "#142334"],
					// legend: {
					//   orient: "vertical",
					//   left: "left",
					// },
					series: [{
						type: "pie",
						radius: "60%",
						label: {
							show: false,
						},
						data: correctRate,
						center: ["50%", "30%"], //这个属性调整图像的位置
					},],
				};
				myChart.setOption(option);
				window.addEventListener("resize", function () {
					myChart.resize();
				});
			});
		},
		getEzuikit(e) {
			console.log("eee:" + e);
			var data = new FormData();
			data.append("appKey", '677028c1456a4ab5afe51e4920be2ac1');
			data.append("appSecret", '570f2c31c56efdb3c8da1a6446905f48');
			fetch('https://open.ys7.com/api/lapp/token/get', {
				method: "POST",
				// headers: {
				//   'Content-Type': 'application/json'
				// },
				body: data
			}).then(response => response.json())
				.then(res => {
					// var accessToken = "at.b1k9uecy8k12mn80bbjxb5zmcc7p2sta-1ao3cc8nmv-03a78g9-moy8jy6rk";
					var accessToken = res.data.accessToken;
					this.playr = new EZUIKit.EZUIKitPlayer({
						id: 'video-container', // 视频容器ID
						accessToken: accessToken,
						url: e,
						template: 'pcLive', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
						plugin: ['talk'],                       // 加载插件，talk-对讲
						width: 350,
						height: 180,
					});
				});
		},
		fullScreen() {
			var playPromise = this.playr.fullScreen();
			playPromise.then((data) => {
				console.log("promise 获取 数据", data)
			})
		}
	},
};
</script>

<style scoped lang='scss'>
.wpbox {
	width: 100%;
	// height: 1080px;
	background: #00065b url(../img/bg.jpg) no-repeat;
	background-size: 100% 100%;
	font-family: 微软雅黑, MicrosoftYahei, sans-serif;
	color: #fff;
	// height: calc(100% - 10px);
	padding: 0 35px 50px 45px;
	box-sizing: border-box;
	// overflow:auto;
}

.bigBox_tilte {
	text-align: center;
	font-size: 0.575rem;
	line-height: 20px;
	padding-top: 0.3rem;
	margin-bottom: 5px;
	/* font-size: 70%; */
	// color: rgba(255, 255, 255, .9);
}

.deptName {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* ::v-deep .el-col-4 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	::v-deep .el-col-6 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	::v-deep .el-col-7 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	} */
@media screen and (max-device-width:1200px) {
	::v-deep .el-col-2 {
		transform: scale(0.7);
	}

	::v-deep .el-col-3 {
		transform: scale(0.7);
	}

	::v-deep .el-col-4 {
		transform: scale(0.7);
	}

	::v-deep .el-col-5 {
		transform: scale(0.7);
	}

	::v-deep .el-col-7 {
		transform: scale(0.7);
	}

	.deptName {
		transform: scale(0.7);
	}

	.tith1 {
		font-size: 18px !important;
	}

	.laji {
		font-size: 12px !important;
	}

	.bigBox_tilte {
		transform: scale(0.7);
		position: relative;
		top: -4px;
	}

	.bigBox_right_two {
		padding: 0 17px 50px !important;
	}

	#chart2_1 {
		font-size: 12px;
	}
}

.bigBox {
	width: 100%;
	height: 93%;

	.bigBox_left {
		// background-color: #fff;
		height: 100%;

		.bigBox_left_one {
			background: url(../img/aleftboxtmidd.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: top center;
			width: 99%;
			height: 30%;
			margin-bottom: 1%;
			padding: 0 20px 10px;

			#myVideo {
				height: 86%;
			}
		}

		.bigBox_left_two {
			background: url(../img/aleftboxtmidd.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: top center;
			width: 98.5%;
			height: 28%;
			margin-bottom: 2%;
			text-align: center;
			overflow: hidden;

			img {
				width: 70%;
				height: 100%;
			}
		}

		.bigBox_left_three {
			background: url(../img/aleftboxtbott.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: top center;
			width: 100%;
			height: 39%;
			padding: 0 10px;

			.chartBox {
				width: 100%;
				height: 85%;
				margin-top: 10px;
			}
		}
	}

	.bigBox_center {
		// background-color: rgb(240, 30, 30);
		height: 100%;

		.bigBox_center_one {
			overflow: hidden;
			background: url(../img/amiddboxttop.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: top center;
			width: 99%;
			height: 58.1%;
			margin-bottom: 1%;
			padding: 4px 0px 0 30px;

			#map-container {
				width: 96%;
				height: 92%;
				overflow: hidden;
				border: 4px solid #7cb9ff;
				border-radius: 20px;
				margin-top: 5px;
				// }
			}
		}

		.bigBox_center_two {
			width: 100%;
			height: 39%;
			overflow: hidden;
			background: url(../img/amiddboxtbott1.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: top center;

			.flexBox {
				display: flex;
				justify-content: space-around;
				// padding-top: 30px;
				height: 90%;
				width: 100%;
				padding: 0 6%;

				#chart2_1 {
					width: 50%;
					height: 100%;
				}

				#chart2_2,
				#chart2_3 {
					width: 25%;
					height: 100%;
				}
			}

			.txt {
				color: #ffffff;
				margin-top: 2vw;
				font-size: 1.2vw;
				text-align: center;

				span {}

				span:nth-child(2n + 1) {
					color: aqua;
					padding-top: 10px;
					text-indent: 1rem;
				}
			}
		}
	}

	.bigBox_right {
		// background-color: rgb(67, 11, 197);
		height: 100%;

		.bigBox_right_one {
			background: url(../img/arightboxtop.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: top center;
			width: 100%;
			height: 57.9%;
			padding: 0px 20px 15%;
			overflow: hidden;

			.el-table {
				background: rgba(1, 202, 217, 0.2) !important;
			}
		}

		.bigBox_right_two {
			background: url(../img/arightboxbott.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: top center;
			width: 100%;
			height: 40%;
			margin-top: 2%;
			box-sizing: border-box;
			padding: 0 25px 46px;
			text-align: center;
		}
	}
}

.bnt {
	height: 9%;
	width: 100%;
	display: inline-block;
}

.left1 {
	width: 18%;
	height: calc(100% - 10%);
	float: left;
	padding-left: 2.2%;
	text-align: center;
}

.pleft1 {
	width: 18.6%;
	float: left;
	padding-left: 2.2%;
	text-align: center;
}

.puleft {
	padding-left: 2.2%;
	width: 35.2%;
	text-align: center;
	height: 100%;
}

.puleft2 {
	width: 35%;
	height: 100%;
	padding-left: 0.4%;
}

.mr_right {
	width: 25%;
	height: 100%;
}

.left2 {
	width: 18%;
	float: left;
	height: 100%;
}

.mrbox {
	float: left;
	width: 79%;
	height: 90%;
}

.mrbox.prbox {
	float: left;
	width: 60%;
	height: 100%;
}

.mrbox_bottom {
	float: left;
	width: 100%;
	height: 28%;
}

.mrbox_top_midd {
	width: 68%;
	float: left;
	height: 100%;
}

.mrbox_topmidd {
	float: left;
	width: 76%;
	padding-left: 0.2%;
	height: 100%;
}

.amidd_bott,
.box {
	overflow: hidden;
}

.pmidd_bott {
	width: 100%;
	height: 57.4%;
}

.mrbox_top_right {
	float: right;
	width: 29.4%;
	padding-right: 1.4%;
	height: 100%;
}

.mrbox_top {
	width: 100%;
	height: 62.4%;
}

.hdmrbox_top {
	width: 100%;
	height: 100%;
}

.lefttime {
	background: url(../img/time.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 84%;
	height: 9.3%;
	margin-left: 6%;
}

.lefttime_text {
	padding: 2% 5% 0 5%;
}

.lefttime_text li {
	font-size: 12px;
	color: rgba(255, 255, 255, 0.9);
	width: 20%;
	float: left;
	height: 22px;
	line-height: 22px;
	text-align: center;
	border-radius: 4px;
}

.lefttime_text li.bg {
	background: rgba(0, 183, 238, 0.1);
}

.lefttime_text li.active {
	background: rgba(0, 183, 238, 0.6);
	color: #fff;
}

.lefttoday {
	background: url(../img/left1box.png);
	background-repeat: no-repeat;
	background-position: -3px 2px;
	width: 350px;
	height: 584px;
	margin: 0 auto;
}

.lefttoday_tit {
	overflow: hidden;
	padding: 1.9% 5% 0.2%;
	height: 6%;
	position: relative;
}

/* .lefttoday_tit.height{height: 12%;} */

.lefttoday_tit.height {
	height: 6%;
}

.lefttoday_number {
	overflow: hidden;
	height: 74%;
	width: 91%;
	margin: 1% 4%;
	background: rgba(1, 202, 217, 0.2);
}

/* .lefttoday_tit p.fl{ font-size: 12px; color: rgba(255,255,255,1);  position: absolute;left: 5%;top: 22%} */

.lefttoday_tit p.fl {
	font-size: 12px;
	color: rgba(255, 255, 255, 1);
	position: absolute;
	left: 5%;
	top: 25%;
}

.lefttoday_tit p.fr {
	font-size: 12px;
	color: rgba(255, 255, 255, 0.6);
	position: absolute;
	right: 5%;
	top: 25%;
}

.lefttoday_tit p.fm {
	font-size: 12px;
	color: rgba(255, 255, 255, 1);
	position: absolute;
	left: 40%;
	top: 25%;
}

/* .lefttoday_tit.height.ht{height: 16%;} */

.lefttoday_tit.height.ht {
	height: 8%;
}

/* .lefttoday_tit.height p.fl{position: absolute;left:5%;top: 15%;} */

.lefttoday_tit.height p.fl {
	position: absolute;
	left: 5%;
	top: 40%;
}

/* .lefttoday_tit.height p.fr{position: absolute;left:5%;top: 65%; right: auto;} */

.lefttoday_tit.height p.fr {
	position: absolute;
	right: 5%;
	top: 40%;
	left: auto;
}

.lefttoday_bar ul {
	position: relative;
	width: 100%;
	height: 100%;
}

.lefttoday_bar li {
	color: #333;
	position: absolute;
	border-radius: 50%;
	font-size: 12px;
	overflow: hidden;
	font-weight: normal;
	text-align: center;
	line-height: 140%;
}

.lefttoday_bar li span {
	padding-top: 30%;
	display: inline-block;
}

.c1 {
	background: #ac3ff2;
}

.c2 {
	background: #ffff00;
}

.c3 {
	background: #0078ff;
}

.c4 {
	background: #9cff00;
}

.c5 {
	background: #ff6c00;
}

.c6 {
	background: #77b5fb;
}

.big0 {
	width: 10px;
	height: 10px;
}

.big1 {
	width: 20px;
	height: 20px;
}

.big2 {
	width: 30px;
	height: 30px;
}

.big3 {
	width: 40px;
	height: 40px;
}

.big4 {
	width: 50px;
	height: 50px;
}

.big5 {
	width: 60px;
	height: 60px;
}

.big6 {
	width: 70px;
	height: 70px;
}

.leftclass {
	background: url(../img/leftb1.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 350px;
	height: 291px;
}

.leftbox2_table {
	background: url(../img/leftbox2.png);
	background-repeat: no-repeat;
	background-position: -2px -2px;
	width: 354px;
	height: 680px;
}

.zhtc_table_title {
	width: 100%;
	font-size: small;
	margin-bottom: 5px;
	text-align: center;
	line-height: 30px;
	background-color: rgb(2, 17, 81);
	// box-shadow: 0px 2px 6px #ccc;
}

.left2_table {
	width: 100%;
	// margin-left: 5%;
	font-size: 12px;
	height: 100%;
	overflow: scroll;

	.zhtc_table_li_content,
	.zhtc_table_li_content2 {
		width: 100%;
		// height: 10%;
		font-size: small;
		text-align: center;
		background: rgba(1, 202, 217, 0.2) url(../img/icosjx.png) no-repeat top left;
		position: relative;
		overflow: hidden;
		padding: 10px 0 5px;
		color: rgba(255, 255, 255, 0.8);
		line-height: 25px;
		cursor: pointer;

		img {
			width: 30px;
			height: 30px;
			// margin-top: 10px;
		}
	}

	.zhtc_table_li_content2 {
		line-height: 30px;
	}

	.zhtc_table_li_content:nth-child(2n + 1),
	.zhtc_table_li_content2:nth-child(2n + 1) {
		background: rgba(0, 255, 255, 0.4) url(../img/icosjx.png) no-repeat top left;
	}
}

.hdleft2_table {
	width: 91%;
	margin-left: 5%;
	font-size: 12px;
	height: 91.6%;
	overflow: hidden;
}

.yellow {
	color: #fff45c;
}

.green {
	color: #00c2fd;
}

.mrbox_tr_box {
	background: url(../img/rbox1.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 354px;
	height: 291px;
}

.mrboxtm-mbox {
	background: url(../img/midtop.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 63.6%;
}

.mrboxtm-b1 {
	background: url(../img/mbox1.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 460px;
	height: 233px;
	float: left;
}

.mrboxtm-b2 {
	background: url(../img/mbox2.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 330px;
	height: 233px;
	float: right;
}

/* .mrbox_tr_box{background:url(../img/rbox1.png);background-size: 100% 100%; background-repeat: no-repeat;background-position: top center; width:350px; height:680px;} */

.hdmrboxtm-mbox {
	background: url(../img/hdbj.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 61.6%;
}

.rbottom_box1 {
	background: url(../img/b-rbox2.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 33.5%;
	height: 89.4%;
	float: left;
}

.rbottom_box2 {
	background: url(../img/bbox2.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 33.5%;
	height: 89.4%;
	float: left;
	margin-left: 0.8%;
}

.rbottom_box3 {
	background: url(../img/b-rbox2.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 30%;
	height: 89.4%;
	float: left;
	margin-left: 1%;
}

.prbottom_box1cont {
	margin-left: 5.2%;
	width: 90.6%;
	height: 82%;
	margin-top: 1.8%;
}

.prbottom_box2cont {
	margin-left: 5.2%;
	width: 90.6%;
	height: 82%;
	margin-top: 1.8%;
}

.prbottom_box3cont {
	margin-left: 5.2%;
	width: 90.6%;
	height: 82%;
	margin-top: 1.8%;
}

.tith2 {
	text-align: center;
	width: 100%;
	font-size: 12px;
	padding-top: 1.9%;
	font-weight: normal;
	letter-spacing: 2px;
	font-weight: normal;
	overflow: hidden;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

.topbnt_left {
	width: 33%;
	padding-top: 38px;
	padding-left: 1%;
	padding: 2vw 0 1vw 1%;
	font-size: 14px;
	line-height: 33px;

	span {
		font-size: 1.1vw;
		color: #dcdcdc;
	}

	::v-deep .el-select {
		width: 50%;
		height: 20% !important;

		.el-input {
			font-size: 1vw !important;

			.el-input__inner {
				background: rgba(0, 0, 0, 0.1);
				box-shadow: 0 0 0.2vw 0.1vw aqua;
				color: #fff;
			}
		}
	}
}

.tith1 {
	width: 33%;
	text-align: center;
	// padding-top: 15px;
	font-weight: bold;
	letter-spacing: 5px;
	font-size: 2vw;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.topbnt_right {
	padding: 2.5% 0px 1% 0;
	width: 25%;
	display: flex;
}

.topbnt_right .laji {
	background: url(../img/bnt.png) center;
	font-size: 14px;
	line-height: 33px;
	background-repeat: no-repeat;
	width: 22%;
	height: 35px;
	float: right;
	text-align: center;
	margin-left: 5px;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.topbnt_right li a {
	text-decoration: none;
	color: #fff;
}

.topbnt_left li.active,
.topbnt_right .active {
	background: url("../img/bntactive.png") no-repeat center;
	// color:#333;
}

.pt1 {
	padding-top: 1.3%;
}

.pt2 {
	padding-top: 2.2%;
}

.pt3 {
	padding-top: 3.3%;
}

.pt6 {
	padding-top: 6px;
}

.pt17 {
	padding-top: 17px;
}

.pt14 {
	padding-top: 14px;
}

.pt12 {
	padding-top: 12px;
}

.pt20 {
	padding-top: 22px;
}

/* .box_pad{ margin: 3px 20px; } */

.mrboxtm-map {
	background: url(../img/mapbg.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 90%;
	margin-left: 4%;
	margin-top: 1%;
	height: 90%;
	position: relative;
}

.hdmrboxtm-map {
	background: url(../img/hdmap.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 90%;
	margin-left: 4%;
	margin-top: 1%;
	height: 66%;
	position: relative;
}

.mrboxtm-map li {
	width: 23px;
	height: 22px;
	line-height: 22px;
	color: #fff;
	text-align: center;
	background-position: center;
	background-repeat: no-repeat;
	font-size: 12px;
	position: absolute;
}

.mrboxtm-map li.bluer {
	background-image: url(../img/blue_rico.png);
}

.mrboxtm-map li.redr {
	background-image: url(../img/red_rico.png);
}

.ricontop {
	width: 29px;
	height: 30px;
	line-height: 30px;
	color: #fff;
	text-align: center;
	bbackground-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	font-size: 12px;
	margin-right: 5px;
	font-style: normal;
	display: inline-block;
}

i.bluer {
	background-image: url(../img/ricon2.png);
}

i.redr {
	background-image: url(../img/ricon1.png);
}

.font14 p.fl,
.font14 p.fr {
	color: #fff;
	font-size: 14px;
}

.mrtop1 {
	background: rgba(1, 202, 217, 0.2);
	overflow: hidden;
	margin: 4px 15px;
}

.widget-inline-box {
	text-align: center;
	color: rgba(255, 255, 255, 0.9);
	width: 50%;
	padding: 10% 0;
	text-align: center;
	font-size: 12px;
	float: left;
	overflow: hidden;
}

.widget-inline-box h3 {
	font-size: 22px;
	font-weight: 100;
	font-weight: normal;
}

.ceeb1fd {
	color: #eeb1fd;
}

.c24c9ff {
	color: #24c9ff;
}

.cffff00 {
	color: #ffff00;
}

.c11e2dd {
	color: #11e2dd;
}

.text-muted {
	font-size: 12px;
	color: #789ce0;
}

.text-muted img {
	vertical-align: middle;
	margin: 0 3px;
}

.mrtop2 {
	margin: 4px 15px;
	padding: 20px 0;
	background: rgba(1, 202, 217, 0.2);
	width: 305px;
}

.tith4 {
	font-size: 12px;
	text-align: center;
}

.mrtop3 {
	margin: 4px 15px;
	padding: 20px 0;
	background: rgba(1, 202, 217, 0.2);
	width: 305px;
}

.mrboxtm-b1wp {
	margin: 4px 25px;
	padding: 20px 0;
	background: rgba(1, 202, 217, 0.2);
	width: 415px;
	overflow: hidden;
}

.mrboxtm_text {
	overflow: hidden;
	padding-left: 12px;
	padding-bottom: 10px;
}

.mrbtext_info {
	background: rgba(1, 202, 217, 0.2);
	font-weight: normal;
	padding: 10px 0;
	text-align: center;
	font-size: 12px;
	color: rgba(255, 255, 255, 0.7);
	float: left;
	margin: 2px 0 10px 8px;
	width: 140px;
}

.mrbtext_info b {
	font-weight: normal;
	font-size: 18px;
}

.lefttoday_number .widget-inline-box {
	width: 25%;
}

/*  警情警力分析 完*/

.aleftboxttop {
	background: url(../img/leftb1.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 99%;
	height: 27.5%;
	margin-bottom: 1%;
}

.aleftboxttop .lefttoday_number {
	background: none;
}

.aleftboxttop .widget-inline-box {
	width: 24.2%;
	margin: 0 0.4%;
	background: rgba(1, 202, 217, 0.2);
	padding: 16% 0;
	height: 100%;
	font-size: 10px;
}

.aleftboxtmidd {
	background: url(../img/aleftboxtmidd.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 98.5%;
	height: 29.4%;
	margin-bottom: 1%;
}

/* .aleftboxtmiddcont{width:91%; height:66%;margin-left: 4.4%; margin-top: 1%;} */

.aleftboxtmiddcont {
	width: 91%;
	height: 72%;
	margin-left: 4.4%;
	margin-top: 1%;
}

.aleftboxtbott {
	background: url(../img/aleftboxtbott.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 40%;
}

/* .aleftboxtbott_cont{width:91%; height:72.6%; margin-top: 1.8%;margin-left: 4.4%;} */

.aleftboxtbott_cont {
	width: 91%;
	height: 79%;
	margin-top: 1.8%;
	margin-left: 4.4%;
}

.aleftboxtbott_cont2 {
	width: 89.9%;
	height: 86.6%;
	margin-top: 2.8%;
	margin-left: 5%;
}

.aleftboxtbott_contr {
	width: 89.9%;
	height: 90.6%;
	margin-top: 1.8%;
	margin-left: 5%;
}

.amiddboxttop {
	overflow: hidden;
	background: url(../img/amiddboxttop.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 99%;
	height: 52.1%;
}

.amiddboxttop_map {
	background: url(../img/img.jpg);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	position: relative;
	width: 90%;
	height: 86%;
	margin: 1.4% 5% 0;
}

.hot_map {
	width: 90%;
	height: 86%;
	margin: 1.4% 5% 0;
}

.amidd_bott {
	width: 100%;
	height: 38%;
	padding-top: 0.8%;
}

/* .amiddboxttop_map span{background:url(../img/camera.png);background-repeat: no-repeat;background-position: 0 0; width: 24px; height: 19px; display: inline-block; position: absolute;} */

/* .amiddboxttop_map span.camera_l{background:url(../img/camera_l.png);background-repeat: no-repeat;background-position: 0 0; width: 24px; height: 19px; display: inline-block; position: absolute;} */

.amiddboxtbott1 {
	background: url(../img/amiddboxtbott1.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 48%;
	height: 91.6%;
	margin-left: 0.5%;
	margin-right: 1%;
}

.amiddboxtbott1content {
	width: 91%;
	height: 86.5%;
	margin-left: 4.8%;
	margin-top: 1.6%;
}

.amiddboxtbott1content2 {
	width: 91%;
	height: 80%;
	margin-left: 4.8%;
	margin-top: 0.6%;
}

.amiddboxtbott2 {
	background: url(../img/amiddboxtbott2.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	float: right;
	width: 49%;
	height: 91.8%;
	margin-right: 0.9%;
}

.amiddboxtbott2content {
	width: 91.8%;
	height: 86%;
	margin-left: 4.4%;
	margin-top: 1.6%;
}

.arightboxtop {
	background: url(../img/arightboxtop.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 51.9%;
}

.arightboxbott {
	background: url(../img/arightboxbott.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 35%;
	margin-top: 3%;
}

.arightboxbottcont {
	width: 91%;
	margin-top: 1%;
	margin-left: 5%;
	height: 79%;
}

.arightboxbottcont2 {
	width: 90%;
	margin-top: 1.6%;
	margin-left: 5.3%;
	height: 85.5%;
}

.plefttoday {
	background: url(../img/pleft1middt.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 96%;
	height: 30%;
	margin-top: 0.4%;
}

.plefttoday .widget-inline-box {
	width: 48%;
	padding: 4% 0;
}

.lpeftmidbot {
	background: url(../img/pleft1middb.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 95.8%;
	height: 27.6%;
	margin-top: 3.4%;
}

.lpeftmidbotcont {
	width: 90.1%;
	margin-top: 2%;
	margin-left: 5.2%;
	height: 82%;
}

.lpeftbot {
	background: url(../img/pleft1middb.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 95.8%;
	height: 27.6%;
	margin-top: 3.4%;
}

.lpeftbotcont {
	width: 90.1%;
	margin-top: 2%;
	margin-left: 5.2%;
	height: 82%;
}

.pleftbox2top {
	background: url(../img/pleftbox2top.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 96.8%;
	height: 35.2%;
	margin-top: 1%;
}

.pleftbox2topcont {
	width: 90.1%;
	margin-top: 2%;
	margin-left: 5%;
	height: 88%;
}

.pleftbox2midd {
	background: url(../img/pleftbox2mid.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 96.8%;
	height: 24.6%;
	margin-top: 3.8%;
}

.pleftbox2middcont {
	width: 90.1%;
	margin-top: 2%;
	margin-left: 5.2%;
	height: 82%;
	overflow: hidden;
}

.lpeft2bot {
	background: url(../img/pleft1middb.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 97.8%;
	height: 24.6%;
	margin-top: 4.2%;
}

.lpeftb2otcont1 {
	width: 100%;
	height: 100%;
}

.hdrightboxtop {
	background: url(../img/hdbjr.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 87%;
}

.lpeftb2otcont {
	width: 90.1%;
	margin-top: 2%;
	margin-left: 5.2%;
	height: 82%;
}

.pmrboxbottom {
	background: url(../img/pmiddboxmidd.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	position: relative;
	width: 100%;
	height: 32.9%;
	margin-top: 1.8%;
}

.pmrboxbottomcont {
	width: 94.1%;
	margin-top: 1.1%;
	margin-left: 3.1%;
	height: 76%;
}

.mrbox_bottom_bott {
	width: 100%;
	height: 27%;
	margin-top: 1.8%;
}

.pmrtop {
	background: url(../img/prighttop.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 56.6%;
}

.pmrtop_contheight {
	height: 30%;
	width: 100%;
	overflow: hidden;
}

.pmrtop1 {
	background: url(../img/prighttop.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 40.4%;
}

.pmrtop_cont1 {
	height: 87%;
	width: 100%;
	overflow: hidden;
}

.pmrmiddcont {
	width: 98.1%;
	margin-top: 1.1%;
	margin-left: 3.1%;
	height: 64%;
}

.pmrtop_contheight .widget-inline-box {
	padding: 5% 0;
}

.lefttoday_bar {
	height: 56%;
	width: 100%;
}

.pmrtop_cont {
	background: rgba(1, 202, 217, 0.2);
	width: 90.6%;
	height: 86%;
	margin: 2.2% 0 0 5.1%;
}

.pmrtop_wid .widget-inline-box {
	width: 33%;
}

.pulefttop {
	background: url(../img/pulefttop.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 29%;
}

.puleftboxtmidd {
	background: url(../img/puleftmidd.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 28.6%;
	margin-top: 1%;
}

.puleftboxtbott {
	background: url(../img/puleftbott.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 28%;
	margin-top: 1%;
}

.pumiddboxttop1 {
	background: url(../img/pumiddtop1.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 47.7%;
	height: 100%;
	padding-left: 1.4%;
}

.pumiddboxttop2 {
	background: url(../img/pumiddtop2.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 47.7%;
	height: 100%;
	margin-left: 1.4%;
}

.pmiddboxtbott {
	background: url(../img/pumiddbott.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 98.4%;
	height: 27.8%;
	margin-top: 2%;
}

.purightboxtop {
	background: url(../img/purighttop.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 29%;
}

.purightboxmidd {
	background: url(../img/purightmidd.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 28%;
	margin-top: 1.8%;
}

.purightboxbott {
	background: url(../img/purightbott.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
	width: 100%;
	height: 28%;
	margin-top: 2.2%;
}

.purightboxtopcont {
	width: 90.2%;
	height: 77%;
	margin-left: 5%;
}

.purightboxmiddcont {
	width: 90.2%;
	height: 77%;
	margin-left: 5%;
}

.purightboxbottcont {
	width: 90.2%;
	height: 77%;
	margin-left: 5%;
}

.pumiddboxtbott1cont {
	width: 62%;
	height: 100%;
	margin-left: 3%;
}

.tith2 span {
	display: inline-block;
	float: left;
	width: 40%;
}

.pbox {
	width: 100%;
	height: 76%;
}

.pulefttoday_bar {
	width: 46.7%;
	height: 100%;
	margin-left: 2.5%;
}

.pulefttoday_bar2 {
	width: 46.7%;
	height: 100%;
	margin-right: 2.5%;
}

.puleftboxtmidd1 {
	width: 46.7%;
	height: 100%;
	margin-left: 2.5%;
}

.puleftboxtbott1 {
	width: 62.7%;
	height: 100%;
	margin-left: 2.5%;
}

.puleftboxtbott2 {
	width: 30.7%;
	height: 100%;
	margin-right: 2.5%;
}

.puleft2height {
	width: 96%;
	height: 88%;
	margin-left: 3%;
}

.puleftbox2bott_cont {
	width: 100%;
	height: 100%;
}

.pulefttoday_bar,
.puleftboxtbott2,
.pumiddboxtbott2 {
	background: rgba(1, 202, 217, 0.2);
}

.puleftboxtbott2 .widget-inline-box {
	width: 100%;
	margin: 0;
	padding: 2% 0;
}

.pumiddboxttop1 .widget-inline-box {
	width: 45%;
	background: rgba(1, 202, 217, 0.2);
	margin-left: 2%;
	margin-bottom: 1.5%;
	height: 38%;
}

.pumiddboxttop1 .widget-inline-box p {
	padding-top: 30%;
}

.f30 {
	font-size: 40px !important;
	margin: 10% 0;
}

.pumiddboxtbott2 {
	width: 30.9%;
	height: 100%;
	margin-right: 2.5%;
}

.pumiddboxtbott2 .widget-inline-box {
	width: 100%;
	margin: 0;
	text-align: center;
}

.pumiddboxtbott2 .widget-inline-box p {
	padding-top: 19%;
}

.pumiddboxttop2_cont {
	width: 90%;
	margin-left: 4.5%;
	margin-top: 0;
	height: 85.4%;
	overflow: hidden;
	text-align: left;
}

.pumiddboxttop2_cont ul {
	height: 100%;
}

.pumiddboxttop2_cont li {
	background: rgba(1, 202, 217, 0.2) url(../img/hot.png) no-repeat 12px 12px;
	height: 13.8%;
}

.pumiddboxttop2_cont li p.text_l {
	line-height: 160%;
	width: 95%;
	overflow: hidden;
	padding-left: 10%;
}

.pumiddboxttop2_cont li p.text_r {
	text-align: right;
	width: 99%;
	height: 40%;
}

.pumiddboxttop2_cont li.bg {
	background: rgba(0, 255, 255, 0.4) url(../img/hot.png) no-repeat 12px 12px;
}

.pvr {
	position: relative;
}

.pvr ul {
	position: absolute;
	left: 11%;
	top: 13%;
}

.pvr ul li {
	width: 16px;
	height: 16px;
	text-align: center;
	line-height: 16px;
	margin-top: 82%;
	border-radius: 2px;
	font-size: 12px;
	display: block;
	color: #fff;
	z-index: 1111;
}

.hot1 {
	background-color: #ff0000;
}

.hot2 {
	background-color: #ff7200;
}

.hot3 {
	background-color: #ffbd5e;
}

.hot4 {
	background-color: #b3b3b3;
}

.hot5 {
	background-color: #597a9f;
}

.liwp ul li {
	margin-top: 79%;
}

.hdtop ul li {
	margin-top: 95%;
}

.pulefttoday_bar2 ul {
	position: absolute;
	left: 4.7%;
	top: 8%;
}

.pulefttoday_bar2 ul li {
	margin-top: 110%;
}

.tlbox {
	overflow: hidden;
	height: 74%;
	width: 91%;
	margin: 1% 4%;
	background: rgba(1, 202, 217, 0.2);
	font-size: 12px;
}

.tlbox p.text {
	padding-left: 3%;
}

.tlbox p.text span {
	width: 27.8%;
	color: rgba(255, 255, 255, 0.6);
	display: inline-block;
	text-align: left;
}

.tlbox ul {
	height: 100%;
}

.tlbox li {
	height: 20%;
	padding-top: 5.6%;
}

.tlbox p.rwith {
	width: 90%;
	height: 10px;
	background: #4ab4ff;
	margin: 2% 5%;
}

.tlbox span.rwith_img {
	height: 10px;
	background: #f8e19a;
	float: left;
	display: inline-block;
}

.tlbox p.text span.w12 {
	width: 28%;
	text-align: left;
}

.tlbox p.bgc3 {
	background: #f19ec2;
}

.tlbox span.qgc2 {
	background: #7ecef4;
}

.tlbox p.bgc2 {
	background: #99b0f7;
}

.tlbox span.qgc3 {
	background: #cce198;
}

.tlbox p.text span.tr {
	text-align: right;
	width: 10%;
	padding-right: 3%;
}

.tlbox li span i {
	width: 14px;
	height: 6px;
	display: inline-block;
	margin-right: 3px;
}

.ricon1 {
	background: #f8e19a;
}

.ricon2 {
	background: #7ecef4;
}

.ricon3 {
	background: #f19ec2;
}

.tricon1 {
	background: #4ab4ff;
}

.tricon2 {
	background: #99b0f7;
}

.tricon3 {
	background: #cce198;
}

.hdwid {
	width: 49.6%;
}
</style>
